/** @jsx jsx */
import { jsx, Container, Divider, Box } from 'theme-ui'
import { Link, PageProps, graphql } from 'gatsby'
import Layout from '~/components/layout'
import { ProductDetailPageQuery } from '~/@types/models'
import Breadcrumbs from '../../componentsV2/breadcrumbs'
import useShopifyBreadcrumbs from '~/componentsV2/hooks/useShopifyBreadcrumbs'
import ProductDetailV2 from '~/containers/product-detail-v2'
import { ProductDetailProvider } from '~/containers/product-detail-v2/Provider'
import { H4 } from '~/styled/components/typography'
import { BespokeProductDetail } from '~/containers/product-detail-v2/bespoke-detail'
import { Fragment } from 'react'

interface Props extends PageProps {
  data: ProductDetailPageQuery
}

// product: shopifyProduct(handle: { eq: $slug }, metafields: { template: { nin: "Bespoke" } }) {
export const productDetailQuery = graphql`
  query bespokeProductDetailQuery($slug: String) {
    product: shopifyProduct(handle: { eq: $slug }, metafields: { template: { in: "Bespoke" } }) {
      ...productDetail
      colorOptions {
        ...colorOption
      }
      finishOptions: colorOptions(option: FINISH) {
        ...colorOption
      }
      pairsWellWith {
        ...productCollectionItem
        finishOptions: colorOptions(option: FINISH) {
          ...colorOption
        }
      }
      categoriesProducts(limit: 12) {
        ...productCollectionItem
        finishOptions: colorOptions(option: FINISH) {
          ...colorOption
        }
      }
      youMightLike {
        ...productCollectionItem
        finishOptions: colorOptions(option: FINISH) {
          ...colorOption
        }
      }
      frequentlyPurchasedWith {
        ...productCollectionItem
        finishOptions: colorOptions(option: FINISH) {
          ...colorOption
        }
      }
      colorVariants: colorGroupProducts {
        products {
          ...productCollectionItem
          titleRaw: title(raw: true)
          mainImageSwatch: mainImage(type: SWATCH) {
            ...shopifyImageSrcSetFull
          }
        }
      }
      collections {
        handle
      }
      moreFromCollection {
        ...productCollectionItem
      }
      configuration {
        ...productConfiguration
      }
    }
  }
`

const BespokeProductTemplate = ({ data: { product } }: Props) => {
  const regex = /(<([^>]+)>)/giu

  const productHtmlDescription = product.description
    ? product.description.slice(0, 150)
    : product.descriptionHtml.replace(regex, '').slice(0, 150)

  const productDescription = product.seo?.description
    ? product.seo.description.slice(0, 150)
    : productHtmlDescription

  const brandName = 'Ben Soleimani'
  const productPageTitle = product.seo?.title
    ? product.seo?.title
    : `${product.title} | ${brandName}`

  return (
    <Layout
      headerVariant="default"
      title={productPageTitle}
      seoTitle={productPageTitle}
      image={product.mainImageFront && product.mainImageFront.srcDesktop}
      className="product-page"
      description={productDescription}
      ankleContent={product.metafields.ankleContent}
      template={product.metafields?.template?.[0].toLowerCase()}
    >
      <ProductDetailProvider product={product}>
        <BespokeProductDetail />
      </ProductDetailProvider>
    </Layout>
  )
}

export default BespokeProductTemplate
