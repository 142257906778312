import React, { useMemo, useState } from 'react'
import { useProductDetail } from '../Provider'
import {
  ProductTitle,
  MadeToOrder,
  ProductDescription,
  InquireButton,
  ImageGrid,
  MainImage,
  DetailImage,
  WishlistButton,
  ProductHeader,
  ProductHeaderContent,
  ProductHeaderBottom,
  ProductTitleButtons,
  ProductContent,
} from './styles'
import { H1 } from '~/styled/components/typography'
import { AddFavoriteButton } from '~/componentsV2/addFavoriteBtn'
import { openInquireForm } from '~/components/header/bespoke-header/inquire-form'
import { BespokeInnerMenu } from './bespoke-inner-menu'

export const BespokeProductDetail = () => {
  const { product, productSliders, productVariant } = useProductDetail()
  const [isWishlisted, setIsWishlisted] = useState(false)
  const { mainImage, gridImages } = useMemo(() => {
    return {
      mainImage: productSliders[0].image,
      gridImages: productSliders.slice(1).map(slider => slider.image),
    }
  }, [productSliders])

  // const fakeHTML = `
  //     <p>
  //   Elevate your space with the sleek and sophisticated Palmer tuxedo style sofa. The deco inspired silhouette is enhanced with hand channel tufting, a cerused plinth base, and a luxuriously spacious single seat cushion.
  // </p>

  // <ul>
  //   <li>Channel Tufted Tight Seat and Tight Back Upholstery</li>
  //   <li>Black Steel Cerused Oak Plinth Base</li>
  //   <li>Tuxedo Arm Sofa</li>
  //   <li>Low Profile Height</li>
  //   <li>Foam core seat cushions with down-blend</li>
  //   <li>Available in 7', 8' & 9' Lengths</li>
  //   <li>Available in Performance Boucle, Performance Velvet, Refined Saddle Leather</li>
  //   <li>Pairs with Palmer Lounge Chair</li>
  //   <li>See FAQ for product care recommendations</li>
  //   <li>Imported</li>
  // </ul>
  // `

  return (
    <div>
      <ProductHeader>
        <BespokeInnerMenu />
        <ProductHeaderContent>
          <MainImage>
            <img src={mainImage} alt={product.title} />
            {/* <WishlistButton
              onClick={() => setIsWishlisted(!isWishlisted)}
              $isWishlisted={isWishlisted}
            /> */}
          </MainImage>
        </ProductHeaderContent>
        <ProductHeaderBottom>
          <ProductTitle>
            <H1>{product.title}</H1>
            <ProductTitleButtons>
              {/* <AddFavoriteButton
                product={product}
                productVariant={productVariant}
                sfl={false}
                hiddenModal={true}
                icon={true}
                onlyButton={true}
              /> */}
              <InquireButton onClick={() => openInquireForm({ product })}>INQUIRE</InquireButton>
            </ProductTitleButtons>
          </ProductTitle>
        </ProductHeaderBottom>
      </ProductHeader>

      <ProductContent>
        <MadeToOrder>MADE TO ORDER</MadeToOrder>

        <ProductDescription>
          <p>
            This piece can be crafted to order, customized to your exact specifications in your
            preferred finish, upholstery and scale. Our made-to-order program offers the opportunity
            to tailor select designs to perfectly suit your project. Please contact us for more
            information or to begin your custom project.
          </p>
        </ProductDescription>

        <ImageGrid>
          {gridImages.map((image, index) => (
            <DetailImage key={index}>
              <img src={image} alt="Product detail 1" />
            </DetailImage>
          ))}
        </ImageGrid>
      </ProductContent>
    </div>
  )
}
